import Cookies from 'js-cookie'
import { COMFINO_SID_KEY } from '~/utils/consts'
import removeSession from '~/utils/session-remover'

export default function ({ $axios, store, route }, inject) {
    $axios.defaults.baseURL = process.env.API_CUSTOMER_ORIGIN
    $axios.onRequest(config => {
        config.headers.common[COMFINO_SID_KEY] = Cookies.get(COMFINO_SID_KEY)
        const { unlockToken } = store.state
        if (unlockToken) {
            config.headers.common['Unlock-Token'] = unlockToken
        }
    })

    $axios.interceptors.request.use((request) => {
        if (store.state.isWindowActive === false) {
            return
        }
        return request
    })

    $axios.interceptors.response.use((response) => response, (error) => {
        console.log('is window active', store.state.isWindowActive)
        if (store.state.isWindowActive === false) {
            return
        }

        if (process.env.NODE_ENV === 'development') {
            console.error(error)
        }

        const code = parseInt(error.response && error.response.status)
        if (error.request) {
            error.message = error.request.responseURL + ' returned ' + code
        }

        if (code === 401) {
            removeSession(route.query.token)
            location.reload()
        } else if (code === 403) {
            location.reload()
        }

        return Promise.reject(error)
    })
}
